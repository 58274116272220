import { RRule } from 'rrule';
import dayjs from 'dayjs';

const handlePreventKeyDown = (event) => {
  // Disallow non-numeric characters, decimal points, and negative signs
  if (event.key === '-' || (event.key.length === 1 && typeof event.key === 'string' && !/[0-9]/.test(event.key))) {
    event.preventDefault();
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== 'string') {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

const assertIsCustomRRULE = (rrule) => {
  if (!rrule) {
    return false;
  }

  const rruleObj = RRule.fromString(rrule);

  return rruleObj.origOptions.interval > 1;
};

const convertArrayToText = (array) => {
  let text = '';

  if (array?.length) {
    text = array.map((elem) => elem.name).join(', ');
  }

  return text;
};

const getRRuleDateTimeComponents = (date) => {
  if (!date) {
    return {};
  }

  const year = dayjs(date).year();
  const month = dayjs(date).month() + 1;
  const day = dayjs(date).date();

  return { year, month, day };
};

export { handlePreventKeyDown, capitalizeFirstLetter, assertIsCustomRRULE, convertArrayToText, getRRuleDateTimeComponents };
