import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const SurveyAnswerPage = Loadable(lazy(() => import('views/surveys/survey-answer')));

const absenceFormRoute = import.meta.env.VITE_APP_ABSENCE_SURVEY_ROUTE;

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/surveys/${import.meta.env.VITE_APP_ABSENCE_SURVEY_TYPE_ID}`);
  }, [navigate]);
  return null;
};

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const childRoutes = [
  {
    path: '/surveys/:surveyTypeUID',
    element: <SurveyAnswerPage />
  },
  {
    path: '/surveys/survey/:surveyUID',
    element: <SurveyAnswerPage />
  }
];

if (typeof absenceFormRoute === 'string' && absenceFormRoute.trim() !== '') {
  childRoutes.push({
    path: absenceFormRoute,
    element: <Redirect />
  });
}

const SurveyRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: childRoutes
};

export default SurveyRoutes;
