const ManagerAccessRolePermissions = {
  VIEW_BOOKING_DASHBOARD: {
    // dashboards
    key: 'VIEW_BOOKING_DASHBOARD',
    name: 'View Booking Dashboard',
    group: 'Dashboards',
    description: `Allows you to view and monitor the Booking metrics`
  },
  VIEW_SURVEY_DASHBOARD: {
    key: 'VIEW_SURVEY_DASHBOARD',
    name: 'View Survey Dashboard',
    group: 'Dashboards',
    description: `Allows you to view and monitor the Survey metrics`
  },
  // respondents
  RESPONDENT_LIST: {
    key: 'RESPONDENT_LIST',
    name: `View List of Respondents`,
    description: `Allows you to view list of assigned respondentss`,
    group: 'respondents'
  },
  RESPONDENT_READ: {
    key: 'RESPONDENT_READ',
    name: 'View Profile',
    description: `Allows you to view the Respondent's profile including profile details `,
    group: 'respondents'
  },
  RESPONDENT_WRITE: {
    key: 'RESPONDENT_WRITE',
    name: 'Edit Profile',
    description: `Allows you to edit the Respondent's profile details including email and phone`,
    group: 'respondents'
  },
  RESPONDENT_DOCUMENT_READ: {
    key: 'RESPONDENT_DOCUMENT_READ',
    name: 'View Document',
    description: `Allows you to view documents that were uploaded for this Respondent`,
    group: 'respondents'
  },
  RESPONDENT_DOCUMENT_WRITE: {
    key: 'RESPONDENT_DOCUMENT_WRITE',
    name: 'Upload Document',
    description: `Allows you to upload documents for this Respondent's profile`,
    group: 'respondents'
  },
  RESPONDENT_NOTE_READ: {
    key: 'RESPONDENT_NOTE_READ',
    name: 'View Notes',
    description: `Allows you to view notes created for this Respondent`,
    group: 'respondents'
  },
  RESPONDENT_NOTE_WRITE: {
    key: 'RESPONDENT_NOTE_WRITE',
    name: 'Create and Delete Notes',
    description: `Allows you to create and delete notes for this Respondent`,
    group: 'respondents'
  },
  // bookings
  BOOKING_READ: {
    key: 'BOOKING_READ',
    name: 'View Bookings',
    description: `
      <p>Allows you to view:</p>
      <ul>
        <li>Bookings made to any Manager at your location and</li>
        <li>Bookings specifically assigned to you as Manager at your location</li>
      </ul>
      <p>It does not allow you to view Bookings specifically assigned to other Managers at your location.</p>
    `,
    group: 'bookings'
  },
  BOOKING_READ_INCLUDE_ALL_RESPONDENT_BOOKINGS: {
    key: 'BOOKING_READ_INCLUDE_ALL_RESPONDENT_BOOKINGS',
    name: 'View All Bookings',
    description: `
      <p>Allows you to view:</p>
      <ul>
        <li>Every Booking made at your location</li>
      </ul>
    `,
    group: 'bookings',
    subPermission: true
  },
  BOOKING_READ_INCLUDE_AFFILIATE_BOOKINGS: {
    key: 'BOOKING_READ_INCLUDE_AFFILIATE_BOOKINGS',
    name: 'View Affiliate Bookings',
    description: `
      Allows you to view affiliate bookings
    `,
    group: 'bookings',
    subPermission: true
  },
  BOOKING_CALENDAR_READ: {
    key: 'BOOKING_CALENDAR_READ',
    name: 'View Bookings via Booking Calendar',
    description: `Allows viewing of bookings via the Calendar`,
    group: 'bookings'
  },
  BOOKING_CREATE_VIA_BOOKING_PAGE: {
    key: 'BOOKING_CREATE_VIA_BOOKING_PAGE',
    name: 'Create Bookings via booking page',
    description: `Allows making a Booking from Booking Pages.`,
    group: 'bookings'
  },
  BOOKING_CREATE_VIA_CALENDAR: {
    key: 'BOOKING_CREATE_VIA_CALENDAR',
    name: 'Create Bookings via calendar',
    description: `Allows making a Booking through Calendar.`,
    group: 'bookings'
  },
  EVENT_CREATE_VIA_CALENDAR: {
    key: 'EVENT_CREATE_VIA_CALENDAR',
    name: 'Create/Update Event via calendar',
    description: `Allows making and updating an Event through Calendar.`,
    group: 'bookings'
  },
  BOOKING_WRITE: {
    key: 'BOOKING_WRITE',
    name: 'Edit Booking Status',
    description: `Allows changing the status of a Booking.`,
    group: 'bookings'
  },
  BOOKING_DOCUMENT_READ: {
    key: 'BOOKING_DOCUMENT_READ',
    name: 'View Booking Document',
    description: `Allows you to see Booking documents added to a booking`,
    group: 'bookings'
  },
  BOOKING_DOCUMENT_WRITE: {
    key: 'BOOKING_DOCUMENT_WRITE',
    name: 'Upload Booking Document',
    description: `Allows you to upload a Booking document for a booking`,
    group: 'bookings'
  },
  // surveys
  SURVEY_READ: {
    key: 'SURVEY_READ',
    name: 'View Survey',
    description: `Allows you to view Surveys created for this Respondent`,
    group: 'surveys'
  },
  SURVEY_WRITE: {
    key: 'SURVEY_WRITE',
    name: 'Create and Answer Survey',
    description: `Allows you to create surveys and answer them for this Respondent `,
    group: 'surveys'
  }
};

export default ManagerAccessRolePermissions;
