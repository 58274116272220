import { lazy } from 'react';
import { Navigate } from 'react-router';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Unauthorised from 'views/unauthorised/Unauthorised';
import PrivateRoute from './utils/PrivateRoute';
import ManagerAccessRolePermissions from 'constants/managerAccessRolePermissions';

// Dashboard
const DashboardBookingStats = Loadable(lazy(() => import('views/shared/dashboard-booking-stats')));
const DashboardSurveyStats = Loadable(lazy(() => import('views/shared/dashboard-survey-stats')));

// pages
const ViewAllRespondents = Loadable(lazy(() => import('views/manager/view-all-respondents')));
const Surveys = Loadable(lazy(() => import('views/manager/view-surveys')));
const Survey = Loadable(lazy(() => import('views/manager/view-survey')));
const ViewRespondent = Loadable(lazy(() => import('views/shared/respondent')));
const AnswerRespondentSurvey = Loadable(lazy(() => import('views/shared/answer-respondent-survey')));
const ViewRespondentSurvey = Loadable(lazy(() => import('views/shared/view-respondent-survey')));
const SurveyDashboard = Loadable(lazy(() => import('views/manager/survey-dashboard')));
// const AbsenceManagement = Loadable(lazy(() => import('views/manager/absence-management')));

// Manage Booking Pages
const ManageBookingPages = Loadable(lazy(() => import('views/manager/manage-booking-pages')));
const CreateBookingFromBookingPage = Loadable(lazy(() => import('views/manager/manage-booking-pages/create-booking')));
// Manage Bookings
const ManageBookings = Loadable(lazy(() => import('views/manager/manage-bookings')));
// Manage Bookings - Calendar
const ManageBookingCalendar = Loadable(lazy(() => import('views/manager/manage-booking-calendar')));

// full screen dialgos
const EnterExistingSurvey = Loadable(lazy(() => import('views/shared/enter-existing-survey')));
const IntroScreen = Loadable(lazy(() => import('views/shared/intro-screen')));
const FinishSurvey = Loadable(lazy(() => import('views/manager/finish-survey')));
const ExitSurvey = Loadable(lazy(() => import('views/manager/exit-survey')));

// ==============================|| MANAGER ROUTING ||============================== //

const ManagerRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/respondents" />
    },
    {
      path: '/dashboard-booking-stats',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.VIEW_BOOKING_DASHBOARD.key}>
          <DashboardBookingStats />
        </PrivateRoute>
      )
    },
    {
      path: '/dashboard-survey-stats',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.VIEW_SURVEY_DASHBOARD.key}>
          <DashboardSurveyStats />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.RESPONDENT_LIST.key}>
          <ViewAllRespondents />
        </PrivateRoute>
      )
    },
    {
      path: '/surveys',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_READ.key}>
          <Surveys />
        </PrivateRoute>
      )
    },
    {
      path: '/view-survey/:surveyId',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_READ.key}>
          <Survey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/view-respondent/:respondentId',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.RESPONDENT_READ.key}>
          <ViewRespondent />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/view-respondent/:respondentId/booking/:bookingId',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.RESPONDENT_READ.key}>
          <ViewRespondent />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/intro-screen/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_WRITE.key}>
          <EnterExistingSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/intro-screen/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_WRITE.key}>
          <IntroScreen />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_WRITE.key}>
          <AnswerRespondentSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/dashboard/survey-parent/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_WRITE.key}>
          <SurveyDashboard />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/finish-survey/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_WRITE.key}>
          <FinishSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/exit-survey/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_WRITE.key}>
          <ExitSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/view-respondent-survey/:surveyID',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.SURVEY_READ.key}>
          <ViewRespondentSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.BOOKING_CREATE_VIA_BOOKING_PAGE.key}>
          <ManageBookingPages />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-bookings',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.BOOKING_READ.key}>
          <ManageBookings />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages/booking-page/:bookingPageUid/',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.BOOKING_CREATE_VIA_BOOKING_PAGE.key}>
          <CreateBookingFromBookingPage />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-calendar',
      element: (
        <PrivateRoute role={ManagerAccessRolePermissions.BOOKING_CALENDAR_READ.key}>
          <ManageBookingCalendar />
        </PrivateRoute>
      )
    },
    // {
    //   path: '/absence-management',
    //   element: <AbsenceManagement />
    // },
    {
      path: '/forbidden',
      element: <Unauthorised />
    }
  ]
};

export default ManagerRoutes;
