// mui
import { Typography, List } from '@mui/material';

// project imports
import { useGetEntityName } from 'utils/entities';

const ManagerScheduleConflictDialog = () => {
  const managerEntityName = useGetEntityName('manager');

  return (
    <List dense>
      <Typography gutterBottom variant="h5" mb={3}>
        {`The selected date and time is in conflict with the selected ${managerEntityName}'s schedule.`}
      </Typography>
    </List>
  );
};

export default ManagerScheduleConflictDialog;
