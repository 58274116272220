import { Link } from 'react-router';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import customizationConfig from 'customizationConfig';
import SidebarLogo from 'ui-component/logos/SidebarLogo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={customizationConfig.defaultPath}>
    <SidebarLogo />
  </ButtonBase>
);

export default LogoSection;
