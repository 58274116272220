// dayjs
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// project constants
import { bookingStatuses, preferredTimeFrameOptions } from 'constants/bookings';

// project utils
import callAzureFunction from 'utils/call-azure-function';
import callAzureFunctionPublic from './call-azure-function-public';
import { areIntervalsOverlapping } from './date-helpers';
import { capitalizeFirstLetter, getRRuleDateTimeComponents } from './form-helpers';

import { RRule, datetime } from 'rrule';
import { managerLocationOptions } from 'constants/managerOptions';
import { calendarModes } from 'constants/calendar';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

/**
 * Gets a `Dayjs` object with a set time depending on the provided parameter values.
 * @param {Number} hours Number of hours
 * @param {Number} min Number of minutes
 * @param {Number} sec Number of seconds
 * @param {Number} ms Number of milliseconds
 * @param {Date} date Date to use as a base, if not provided, current Date is used
 * @returns {Dayjs} Dayjs object
 */
const getDateWithCustomTime = (hours = 0, min = 0, sec = 0, ms = 0, date = null) => {
  const newDate = date ? dayjs(date) : dayjs();
  return newDate.hour(hours).minute(min).second(sec).millisecond(ms);
};

const validHours = (value) => Boolean(typeof value === 'number' && value >= 0 && value <= 23);

const validMinutes = (value) => Boolean(typeof value === 'number' && value >= 0 && value <= 59);

/**
 * Determines if the given day is valid work day or not.
 * @param {Date} date Date to be checked
 * @param {object} location Location object. If `location` is not provided, `locationWorkDays` will be used next
 * @param {string} locationWorkDays Comma separated list of work days e.g. `1,2,4,5`
 * @returns {boolean} `true` if day is valid, `false` otherwise
 */
const dayIsValidWorkday = (date, location = null, locationWorkDays = null, workDaysArray = null) => {
  if (!(date instanceof Date || date instanceof dayjs)) return false;

  const workDays =
    location?.workDays?.split(',')?.map((day) => Number(day)) ||
    locationWorkDays?.split(',')?.map((day) => Number(day)) ||
    workDaysArray ||
    [];
  return Boolean(workDays.includes(dayjs(date).day()));
};

const hasNoLunchTime = (locationObj) => {
  if (!locationObj || typeof locationObj !== 'object') {
    return true;
  }

  return (
    locationObj.lunchStartHours === null ||
    locationObj.lunchStartMinutes === null ||
    locationObj.lunchEndHours === null ||
    locationObj.lunchEndMinutes === null
  );
};

/**
 * Determines if the given day is unavailalbe or not.
 * @param {Date} date Date to be checked
 * @param {object} location Location object. If `location` is not provided, `locationUnavailableDays` will be used next
 * @param {object[]} locationUnavailableDays Array of unavailable days
 * @returns {boolean} `true` if day is unavailalbe, `false` otherwise
 */
const dayIsUnavailable = (date, location = null, locationUnavailableDays = []) => {
  if (!(date instanceof Date || date instanceof dayjs)) return false;

  const unavailableDays = location?.UnavailableDays || locationUnavailableDays || [];

  // recurring & non recurring dates
  const udRecurring = unavailableDays?.filter((ud) => ud.recurring) || [];
  const udNonRecurring = unavailableDays?.filter((ud) => !ud.recurring) || [];

  const unavailableRecurring = udRecurring.find((ud) => {
    const udDate = dayjs(ud.date);
    // check if day of month and month are the same
    return Boolean(
      ud.allDay &&
        !ud.isBlockSlot &&
        dayjs(date).get('date') === dayjs(udDate).get('date') &&
        dayjs(date).get('month') === dayjs(udDate).get('month')
    );
  });
  const unavailableNonRecurring = udNonRecurring.find((ud) => {
    const udDateISO = dayjs(ud.date).format('YYYY-MM-DD');
    // check if both ISO dates and time are the same
    return Boolean(ud.allDay && !ud.isBlockSlot && udDateISO === dayjs(date).format('YYYY-MM-DD'));
  });

  return Boolean(unavailableRecurring || unavailableNonRecurring);
};

/**
 * Determines if the given day is blocked or not.
 * @param {Date} date Date to be checked
 * @param {object} location Location object. If `location` is not provided, `locationUnavailableDays` will be used next
 * @param {object[]} locationUnavailableDays Array of unavailable days
 * @returns {boolean} `true` if day is blocked, `false` otherwise
 */
const dayIsBlockedAtLocation = (date, location = null, locationUnavailableDays = []) => {
  if (!(date instanceof Date || date instanceof dayjs)) return false;

  if (!location) {
    return false;
  }

  const unavailableDays = location?.UnavailableDays || locationUnavailableDays || [];

  // recurring & non recurring dates
  const udRecurring = unavailableDays?.filter((ud) => ud.recurring) || [];
  const udNonRecurring = unavailableDays?.filter((ud) => !ud.recurring) || [];

  const unavailableRecurring = udRecurring.find((ud) => {
    const udDate = dayjs(ud.date);
    // check if unavailableDay is not allDay, day of month and month are the same
    // and day-time is in between blocked time range
    return (
      ud.allDay &&
      ud.isBlockSlot &&
      dayjs(date).get('date') === dayjs(udDate).get('date') &&
      dayjs(date).get('month') === dayjs(udDate).get('month')
    );
  });

  const unavailableNonRecurring = udNonRecurring.find((ud) => {
    const udDateISO = dayjs(ud.date).format('YYYY-MM-DD');
    // check if unavailableDay is not allDay, both ISO dates and time are the same
    // and day-time is in between blocked time range
    return ud.allDay && ud.isBlockSlot && udDateISO === dayjs(date).format('YYYY-MM-DD');
  });

  return Boolean(unavailableRecurring || unavailableNonRecurring);
};

/**
 * Determines if the given day is blocked or not.
 * @param {Date} date Date to be checked
 * @param {object} location Location object. If `location` is not provided, `managersUnavailableDays` will be used next
 * @param {object[]} managersUnavailableDays Array of unavailable days
 * @returns {boolean} `true` if day is blocked, `false` otherwise
 */
const dayIsBlockedOnManager = (date, manager = null, managersUnavailableDays = []) => {
  if (!(date instanceof Date || date instanceof dayjs)) return false;

  if (!manager) {
    return false;
  }

  const unavailableDays = manager?.UnavailableDays || managersUnavailableDays || [];

  // recurring & non recurring dates
  const udRecurring = unavailableDays?.filter((ud) => ud.recurring) || [];
  const udNonRecurring = unavailableDays?.filter((ud) => !ud.recurring) || [];

  const unavailableRecurring = udRecurring.find((ud) => {
    const udDate = dayjs(ud.date);
    // check if unavailableDay is not allDay, day of month and month are the same
    // and day-time is in between blocked time range
    return (
      ud.allDay &&
      ud.isBlockSlot &&
      dayjs(date).get('date') === dayjs(udDate).get('date') &&
      dayjs(date).get('month') === dayjs(udDate).get('month')
    );
  });

  const unavailableNonRecurring = udNonRecurring.find((ud) => {
    const udDateISO = dayjs(ud.date).format('YYYY-MM-DD');
    // check if unavailableDay is not allDay, both ISO dates and time are the same
    // and day-time is in between blocked time range
    return ud.allDay && ud.isBlockSlot && udDateISO === dayjs(date).format('YYYY-MM-DD');
  });

  return Boolean(unavailableRecurring || unavailableNonRecurring);
};

const dateIsValidAtLocation = (date, timeSlot, location) => {
  if (!(date instanceof Date || date instanceof dayjs) || !timeSlot || !location) {
    return false;
  }

  try {
    const timezone = location.timezone || null;
    const dateAtLocationNow = dayjs.tz(dayjs(), timezone);
    const bookingDate = dayjs.tz(`${dayjs(date).format('YYYY-MM-DD')}T${dayjs(timeSlot.start).format('HH:mm')}`, timezone);

    return bookingDate.isAfter(dateAtLocationNow);
  } catch (error) {
    return false;
  }
};

/**
 * Validates if a given date is valid based on the provided recurrence rule.
 *
 * @param {Object} paramObject - An object containing parameters:
 *   @param {dayjs} dayjsdate - The date to be validated, which is in DayJS data type.
 *   @param {dayjs} cycleStartDate - The start date of the cycle.
 *   @param {Array} workdays - An array of weekdays for the rule.
 *   @param {number} weeklyInterval - The interval for weekly recurrence.
 *   @param {RRule} rruleObj - The RRule object for recurrence.
 *   @param {string} rruleStr - The RRule string representation.
 * @returns {boolean} Returns true if the date is valid according to the rule, false otherwise.
 */
const dateIsValidPerRRULE = ({ dayjsdate, cycleStartDate, workdays, weeklyInterval, rruleObj, rruleStr }) => {
  if (!(dayjsdate instanceof dayjs)) return false;

  let isValid = true;

  let rule;

  if (rruleObj && rruleObj instanceof RRule) {
    rule = rruleObj;
  } else if (rruleStr && typeof rruleStr === 'string') {
    rule = RRule.fromString(rruleStr);
  } else if (weeklyInterval && cycleStartDate && cycleStartDate instanceof dayjs && workdays.length) {
    const { year, month, day } = getRRuleDateTimeComponents(cycleStartDate);

    rule = new RRule({
      freq: RRule.WEEKLY,
      interval: weeklyInterval,
      dtstart: datetime(year, month, day),
      byweekday: workdays
    });
  } else {
    return isValid;
  }

  const startDate = dayjsdate.subtract(1, 'day').toDate();
  const thirtyDaysFromStartDate = dayjsdate.add(30, 'days').toDate();

  // https://github.com/jkbrzt/rrule?tab=readme-ov-file#usage
  // get all occurrence dates within a specific period based on the rrule

  const recurrenceSet = rule.between(startDate, thirtyDaysFromStartDate);

  isValid = recurrenceSet.some((date) => dayjs(date).isSame(dayjsdate, 'date'));

  return isValid;
};

const dateIsValidPerRRulesOfAllGZLocations = (dayjsdate, groundZeroLocations) => {
  let isValid = true;

  if (!groundZeroLocations.length) {
    return isValid;
  }

  const allRRules = groundZeroLocations.map((gzLoc) => gzLoc.rrule).filter((rrule) => rrule !== null);

  const validityCheckResultArray = [];

  for (const rrule of allRRules) {
    const rruleObj = RRule.fromString(rrule);
    const isDateValid = dateIsValidPerRRULE({ dayjsdate, rruleObj });

    validityCheckResultArray.push(isDateValid);
  }

  isValid = validityCheckResultArray.includes(true);

  return isValid;
};

const getValidManagerSchedules = (date, managerSchedules) => {
  if (!managerSchedules?.length) {
    return [];
  }
  const validSchedules = managerSchedules.filter((schedule) => {
    const { effectiveStartDate, effectiveUntilDate } = schedule;

    // https://day.js.org/docs/en/plugin/is-between
    const isNotYetExpired = effectiveUntilDate
      ? dayjs(date).isBetween(dayjs(effectiveStartDate), dayjs(effectiveUntilDate), 'day', '[]')
      : dayjs(date).isSameOrAfter(dayjs(effectiveStartDate));

    return isNotYetExpired;
  });

  return validSchedules;
};

const dateIsValidPerManagerSchedules = (dayjsdate, managerSchedules) => {
  let isValid = true;

  const validManagerSchedules = getValidManagerSchedules(dayjsdate, managerSchedules);

  if (!validManagerSchedules?.length) {
    return isValid;
  }

  const allRRules = managerSchedules.map((gzLoc) => gzLoc.rrule).filter((rrule) => rrule !== null);

  const validityCheckResultArray = [];

  for (const rrule of allRRules) {
    const rruleObj = RRule.fromString(rrule);
    const isDateValid = dateIsValidPerRRULE({ dayjsdate, rruleObj });

    validityCheckResultArray.push(isDateValid);
  }

  isValid = validityCheckResultArray.includes(true);

  return isValid;
};

const dateIsValidAsPerTimezone = (date, timeSlot, timezone = null) => {
  if (!(date instanceof Date || date instanceof dayjs) || !timeSlot) {
    return false;
  }

  try {
    const dateAtTimezoneNow = dayjs.tz(dayjs(), timezone);
    const bookingDate = dayjs.tz(`${dayjs(date).format('YYYY-MM-DD')}T${dayjs(timeSlot.start).format('HH:mm')}`, timezone);

    return bookingDate.isAfter(dateAtTimezoneNow);
  } catch (error) {
    return false;
  }
};

const getSelectedGZLocationPerRRule = (dateValue, gzLocations) => {
  const selectedGZLocation = gzLocations.find((gzLoc) => dateIsValidPerRRULE({ dayjsdate: dayjs(dateValue), rruleStr: gzLoc.rrule }));

  return selectedGZLocation;
};

const getAllWorkDays = (arrayOfObjWithWorkDays) => {
  let allWorkDays = [];

  if (!arrayOfObjWithWorkDays || !arrayOfObjWithWorkDays.length) {
    return allWorkDays;
  }

  allWorkDays = arrayOfObjWithWorkDays
    .map((ea) => ea.workDays.split(',').map((d) => Number(d)) || [])
    .flat()
    .reduce((a, day) => {
      if (!a.includes(day)) {
        a.push(day);
      }
      return a;
    }, [])
    // sort
    .sort((a, b) => a - b);

  return allWorkDays;
};

const getEarliestAvailableDate = (location) => {
  let currentDate = dayjs();

  if (!location) {
    return currentDate;
  }

  for (let index = 0; ; index += 1) {
    if (dayIsValidWorkday(currentDate, location) && !dayIsUnavailable(currentDate, location)) {
      break;
    }
    currentDate = currentDate.add(1, 'day');
  }

  return currentDate;
};

const getEarliestDateForGZLocation = (gzLocation) => {
  let currentDate = dayjs();

  if (!gzLocation) {
    return currentDate;
  }

  // search at least 120 days until an available date is found
  for (let index = 0; index <= 120; index += 1) {
    if (dateIsValidPerRRULE({ dayjsdate: currentDate, rruleObj: gzLocation.rrule })) {
      break;
    }
    currentDate = currentDate.add(1, 'day');
  }

  return currentDate;
};

/**
 * Gets the calendar events of a user in Outlook calendar
 * https://learn.microsoft.com/en-us/graph/api/user-list-calendarview?view=graph-rest-1.0&tabs=http#http-request
 * @param {object} options - The options for getting the calendar events.
 * @param {string} options.userPrincipalName - The user principal name to fetch events for.
 * @param {string} [options.managerId] - The manager id to fetch events for. Only used if publicMode is true.
 * @param {Date} options.bookingDate - The start of the time range to fetch events for, in ISO8601 format.
 * @param {string} [options.timezone] - The timezone to fetch events for, in IANA format. Optional, typically passed when manager is in a specific location and null if the manager is MSP
 * @param {boolean} [options.publicMode] - Whether to call the public or private Azure function.
 * @returns {Promise<Array<object>>} An array of calendar events.
 */
const getOutlookCalendarEventsOfUser = async ({ userPrincipalName, managerId = null, bookingDate, location, publicMode }) => {
  const { workStartHours, workStartMinutes, workEndHours, workEndMinutes, timezone } = location || {};

  const requestForOutlookCalendarEvents = {
    url: publicMode ? `public/outlook-calendar/events` : `outlook-calendar/events`,
    method: 'get',
    params: {
      userPrincipalName,
      managerId: publicMode ? managerId : null,
      timeMin: dayjs
        .tz(
          `${dayjs(bookingDate).format('YYYY-MM-DD')}T${String(workStartHours || 8).padStart(2, '0')}:${String(workStartMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeMax: dayjs
        .tz(
          `${dayjs(bookingDate).format('YYYY-MM-DD')}T${String(workEndHours || 17).padStart(2, '0')}:${String(workEndMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timezone
    }
  };
  const response = publicMode
    ? await callAzureFunctionPublic(requestForOutlookCalendarEvents)
    : await callAzureFunction(requestForOutlookCalendarEvents);

  const events = response?.data;

  return events || [];
};

/**
 * Gets the calendar events of a user in Google calendar
 * https://developers.google.com/calendar/api/v3/reference/events/list
 * @param {object} options - The options for getting the calendar events.
 * @param {string} options.userEmailAddress - The user principal name to fetch events for.
 * @param {string} [options.managerId] - The manager id to fetch events for. Only used if publicMode is true.
 * @param {Date} options.bookingDate - The start of the time range to fetch events for, in ISO8601 format.
 * @param {object} [options.location] - The location object for which to fetch events. The location object is used to filter the events by the work hours of the location.
 * @param {boolean} [options.publicMode] - Whether to call the public or private Azure function.
 * @returns {Promise<Array<object>>} An array of calendar events.
 */
const getGoogleCalendarEventsOfUser = async ({ userEmailAddress, managerId, bookingDate, location, publicMode }) => {
  const { workStartHours, workStartMinutes, workEndHours, workEndMinutes, timezone } = location || {};

  const requestForGoogleCalendarEvents = {
    url: publicMode ? `public/google-calendar/events` : `google-calendar/events`,
    method: 'get',
    params: {
      subject: userEmailAddress,
      managerId: publicMode ? managerId : null,
      timeMin: dayjs
        .tz(
          `${dayjs(bookingDate).format('YYYY-MM-DD')}T${String(workStartHours || 8).padStart(2, '0')}:${String(workStartMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeMax: dayjs
        .tz(
          `${dayjs(bookingDate).format('YYYY-MM-DD')}T${String(workEndHours || 17).padStart(2, '0')}:${String(workEndMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeZone: timezone
    }
  };

  const responseForGoogleCalendarEvents = publicMode
    ? await callAzureFunctionPublic(requestForGoogleCalendarEvents)
    : await callAzureFunction(requestForGoogleCalendarEvents);

  const events = responseForGoogleCalendarEvents.data;

  return events || [];
};

const getAllTimeSlotIntervalStartTimes = (workStartTime, workEndTime, timeSlotInterval) => {
  const timeSlotIntervalStartTimes = [];

  if (!timeSlotInterval) {
    return timeSlotIntervalStartTimes;
  }

  let startTime = workStartTime;

  while (dayjs(startTime).isBefore(dayjs(workEndTime))) {
    const newIntervalStartTime = dayjs(startTime).add(timeSlotInterval, 'minute');

    timeSlotIntervalStartTimes.push(startTime);

    startTime = newIntervalStartTime;
  }

  return timeSlotIntervalStartTimes;
};

/**
 * Given an array of start times to check, the latest time slot end and lunch time, determines which next start time to check.
 * If there is a lunch conflict, the next start time to check is the end of the lunch period.
 * If there are more start times to check, the next start time to check is the next start time in the list.
 * Otherwise, the next start time to check is the end of the period.
 *
 * @param {Object} options - An object containing the following properties:
 *   @param {boolean} lunchConflict - Whether there is a lunch conflict.
 *   @param {dayjs} lunchEndTime - Lunch end time.
 *   @param {string} end - The end time of the time slot generated.
 *   @param {dayjs[]} startTimesToCheck - An array of start times to check.
 *   @param {number} startTimesToCheckTracker - The index of the start time to check in startTimesToCheck.
 * @returns {Object} An object with the following properties:
 *   @param {dayjs} nextStartTime - The next start time to check.
 *   @param {number} indexOfNextStartTimeToCheck - The updated start time tracker.
 */
const getNextStartTimeToCheck = ({ lunchConflict, lunchEndTime, end, startTimesToCheck, indexOfNextStartTimeToCheck }) => {
  if (lunchConflict) {
    return {
      nextStartTime: lunchEndTime,
      nextIndexOfStartTimeToCheck: indexOfNextStartTimeToCheck
    };
  }

  const hasMoreStartTimesToCheck = startTimesToCheck.length > 0 && indexOfNextStartTimeToCheck < startTimesToCheck.length;

  if (hasMoreStartTimesToCheck) {
    const nextStartTimeToCheck = startTimesToCheck[indexOfNextStartTimeToCheck];

    if (dayjs(nextStartTimeToCheck).isSameOrBefore(end)) {
      return {
        nextStartTime: nextStartTimeToCheck,
        nextIndexOfStartTimeToCheck: indexOfNextStartTimeToCheck + 1
      };
    }
  }

  return {
    nextStartTime: end,
    nextIndexOfStartTimeToCheck: indexOfNextStartTimeToCheck
  };
};

const createDayjsTime = (hours, minutes) => dayjs().set('hour', hours).set('minute', minutes);

// Helper functions for time comparison
const getLatestTime = (current, comparison) => {
  const currentTime = createDayjsTime(current.hours, current.minutes);
  const comparisonTime = createDayjsTime(comparison.hours, comparison.minutes);
  return currentTime.isAfter(comparisonTime) ? current : comparison;
};

const getEarliestTime = (current, comparison) => {
  const currentTime = createDayjsTime(current.hours, current.minutes);
  const comparisonTime = createDayjsTime(comparison.hours, comparison.minutes);
  return currentTime.isBefore(comparisonTime) ? current : comparison;
};

// Handle lunch time updates
const updateLunchTimes = (schedule, current) => {
  if (hasNoLunchTime(schedule)) {
    return {
      lunchStartHours: current.lunchStartHours,
      lunchStartMinutes: current.lunchStartMinutes,
      lunchEndHours: current.lunchEndHours,
      lunchEndMinutes: current.lunchEndMinutes
    };
  }

  const earliestLunchStart = getEarliestTime(
    { hours: current.lunchStartHours, minutes: current.lunchStartMinutes },
    { hours: schedule.lunchStartHours, minutes: schedule.lunchStartMinutes }
  );

  const latestLunchEnd = getLatestTime(
    { hours: current.lunchEndHours, minutes: current.lunchEndMinutes },
    { hours: schedule.lunchEndHours, minutes: schedule.lunchEndMinutes }
  );

  return {
    lunchStartHours: earliestLunchStart.hours,
    lunchStartMinutes: earliestLunchStart.minutes,
    lunchEndHours: latestLunchEnd.hours,
    lunchEndMinutes: latestLunchEnd.minutes
  };
};

/**
 * Given a location schedule and an array of manager schedules, returns a schedule
 * with the latest work start time and the earliest work end time.
 * If the array of manager schedules is empty, the location schedule is returned.
 * @param {object} locationSchedule - The schedule of the location.
 * @param {Array<object>} managerSchedules - An array of manager schedules.
 * @param {Date} date - The date for which to get the valid time slot schedule.
 * @returns {object} A schedule with the latest work start time and the earliest work end time.
 */
const getValidTimeSlotSchedule = (locationSchedule, managerSchedules, date) => {
  if (!managerSchedules.length) {
    return locationSchedule;
  }

  const workSchedule = managerSchedules
    // filter work schedule that is only applicable to the date per rrule
    .filter((schedule) => dateIsValidPerRRULE({ dayjsdate: date, rruleObj: RRule.fromString(schedule.rrule) }))
    .reduce((schedule, current) => {
      // Get latest work start time
      const latestWorkStart = getLatestTime(
        { hours: current.workStartHours, minutes: current.workStartMinutes },
        { hours: schedule.workStartHours, minutes: schedule.workStartMinutes }
      );

      // Get earliest work end time
      const earliestWorkEnd = getEarliestTime(
        { hours: current.workEndHours, minutes: current.workEndMinutes },
        { hours: schedule.workEndHours, minutes: schedule.workEndMinutes }
      );

      // Update work times
      const updatedSchedule = {
        ...schedule,
        workStartHours: latestWorkStart.hours,
        workStartMinutes: latestWorkStart.minutes,
        workEndHours: earliestWorkEnd.hours,
        workEndMinutes: earliestWorkEnd.minutes,
        ...updateLunchTimes(schedule, current)
      };

      return updatedSchedule;
    }, locationSchedule);

  return workSchedule;
};

/**
 * Gets the available time slots for a service at a location on a given date.
 * The time slots are calculated by taking into account the work hours, lunch break, and existing bookings at the location.
 * The time slots are also filtered to make sure they do not conflict with the manager's calendar events if the manager is in a specific location.
 * If the manager is an MSP, the time slots are not filtered by the manager's calendar events.
 * @param {object} options - The options for getting the available time slots.
 * @param {Date} options.date - The date to get the available time slots for.
 * @param {object} options.service - The service object for which to get the available time slots.
 * @param {object} options.location - The location object for which to get the available time slots.
 * @param {object} options.manager - The manager object for which to get the available time slots. The manager object is used to filter the time slots by the manager's calendar events.
 * @param {boolean} options.managerHasOutlookCalendar - Whether the manager has an Outlook calendar.
 * @param {boolean} options.managerHasGoogleCalendar - Whether the manager has a Google calendar.
 * @param {boolean} options.publicMode - Whether to call the public or private Azure function.
 * @param {number} options.managerOption - The selected manager location option. Allows calendar event conflict checks if the value is specific manager at location.
 * @param {array} options.managerSchedules - An array of manager schedules.
 * @param {number} options.timeSlotInterval - The time slot interval in minutes to be scanned.
 * @returns {Promise<Array<object>>} An array of available time slots.
 */
const getAvailableTimeSlots = async ({
  date,
  service,
  location,
  manager,
  managerHasOutlookCalendar = false,
  managerHasGoogleCalendar = false,
  publicMode = false,
  managerSchedules = [],
  managerOption,
  timeSlotInterval = 0
}) => {
  const timeSlots = [];

  if (!service || !location) {
    return timeSlots;
  }

  const validManagerSchedules = getValidManagerSchedules(date, managerSchedules);

  const {
    workStartHours,
    workStartMinutes,
    workEndHours,
    workEndMinutes,
    lunchStartHours,
    lunchStartMinutes,
    lunchEndHours,
    lunchEndMinutes
  } = getValidTimeSlotSchedule(location, validManagerSchedules, date);

  let workStartTime = null;
  let workEndTime = null;
  let lunchStartTime = null;
  let lunchEndTime = null;

  if (validHours(workStartHours) && validMinutes(workStartMinutes) && validHours(workEndHours) && validMinutes(workEndMinutes)) {
    workStartTime = getDateWithCustomTime(workStartHours, workStartMinutes, 0, 0, date);
    workEndTime = getDateWithCustomTime(workEndHours, workEndMinutes, 0, 0, date);
  } else {
    return timeSlots;
  }

  if (validHours(lunchStartHours) && validMinutes(lunchStartMinutes) && validHours(lunchEndHours) && validMinutes(lunchEndMinutes)) {
    lunchStartTime = getDateWithCustomTime(lunchStartHours, lunchStartMinutes, 0, 0, date);
    lunchEndTime = getDateWithCustomTime(lunchEndHours, lunchEndMinutes, 0, 0, date);
  }

  const serviceDuration = service.duration;

  if (!serviceDuration) {
    return timeSlots;
  }

  const request = {
    url: publicMode ? `/public/bookings/location/${location.id}` : `bookings/location/${location.id}`,
    method: 'get',
    params: {
      date: dayjs(date).format('YYYY-MM-DD'),
      excludeStatuses: [bookingStatuses.Canceled.value, bookingStatuses.DidNotAttend.value].join(',')
    }
  };

  // get all bookings for this date at this location
  const response = publicMode ? await callAzureFunctionPublic(request) : await callAzureFunction(request);

  const unavailableBookingSlots = response.data.map((booking) => {
    const { startHours, startMinutes, endHours, endMinutes } = booking;
    return {
      start: getDateWithCustomTime(startHours, startMinutes, 0, 0, date),
      end: getDateWithCustomTime(endHours, endMinutes, 0, 0, date)
    };
  });

  const outlookCalendarEvents =
    managerHasOutlookCalendar && manager && managerOption === managerLocationOptions.specific.value
      ? await getOutlookCalendarEventsOfUser({
          userPrincipalName: manager.emailAddress,
          managerId: manager.id,
          bookingDate: date,
          location,
          publicMode
        })
      : [];

  const googleCalendarEvents =
    managerHasGoogleCalendar && manager && managerOption === managerLocationOptions.specific.value
      ? await getGoogleCalendarEventsOfUser({
          userEmailAddress: manager.emailAddress,
          managerId: manager.id,
          bookingDate: date,
          location,
          publicMode
        })
      : [];

  // get all blocked slots at this Location on the selected day
  const getBlockedSlotsAtThisLocRes = await callAzureFunctionPublic({
    url: `/locations/blocked-events`,
    method: 'get',
    params: {
      filteredLocationIds: JSON.stringify([location.id]),
      isNotAllDay: true,
      date: dayjs(date).format('YYYY-MM-DD')
    }
  });

  const blockedSlotsAtLocation = getBlockedSlotsAtThisLocRes.data.map((booking) => {
    const { startHours, startMinutes, endHours, endMinutes } = booking;
    return {
      start: getDateWithCustomTime(startHours, startMinutes, 0, 0, date),
      end: getDateWithCustomTime(endHours, endMinutes, 0, 0, date)
    };
  });

  let blockedSlotsOfManager = [];

  if (managerOption === managerLocationOptions.specific.value) {
    // get all blocked slots of this Manager on the selected day
    // const getBlockedSlotsAtThisManagerRes = await callAzureFunction({
    const getBlockedSlotsAtThisManagerRes = await callAzureFunctionPublic({
      url: `/managers/blocked-events`,
      method: 'get',
      params: {
        filteredManagerIds: JSON.stringify([manager?.id]),
        isNotAllDay: true,
        date: dayjs(date).format('YYYY-MM-DD')
      }
    });

    blockedSlotsOfManager = getBlockedSlotsAtThisManagerRes.data.map((booking) => {
      const { startHours, startMinutes, endHours, endMinutes } = booking;
      return {
        start: getDateWithCustomTime(startHours, startMinutes, 0, 0, date),
        end: getDateWithCustomTime(endHours, endMinutes, 0, 0, date)
      };
    });
  }

  // get all end times of existing bookings and 3rd party calendar events
  const allBookingEndTimes = unavailableBookingSlots.map((booking) => booking.end);
  const allBlockedSlotsAtLocationEndTimes = blockedSlotsAtLocation.map((blockedSlot) => blockedSlot.end);
  const allBlockedSlotsAtManagerEndTimes = blockedSlotsOfManager.map((blockedSlot) => blockedSlot.end);

  const allGoogleEventEndTimes = googleCalendarEvents.map((event) => {
    const { endDate, endHours, endMinutes } = event;
    return getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
  });

  const allOutlookEventEndTimes = outlookCalendarEvents.map((event) => {
    const { endDate, endHours, endMinutes } = event;
    return getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
  });

  const allTimeSlotIntervalStartTimes = getAllTimeSlotIntervalStartTimes(workStartTime, workEndTime, timeSlotInterval);

  const startTimesToCheck = [
    ...new Set([
      ...allTimeSlotIntervalStartTimes,
      ...allBookingEndTimes,
      ...allGoogleEventEndTimes,
      ...allOutlookEventEndTimes,
      ...allBlockedSlotsAtLocationEndTimes,
      ...allBlockedSlotsAtManagerEndTimes
    ])
  ];

  // start and end time for time slot
  let start = workStartTime;
  let end = dayjs(start).add(serviceDuration, 'minute');

  let indexOfNextStartTimeToCheck = 0;
  startTimesToCheck.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 1));

  while (start >= workStartTime && start < workEndTime && end > workStartTime && end <= workEndTime) {
    // check for conflicts with lunch time
    const lunchConflict =
      lunchStartTime && lunchEndTime && areIntervalsOverlapping({ start: lunchStartTime, end: lunchEndTime }, { start, end });
    // check for conflicts with at least one existing booking
    const bookingConflict = unavailableBookingSlots.find(
      // eslint-disable-next-line no-loop-func
      (slot) => areIntervalsOverlapping({ start: slot.start, end: slot.end }, { start, end })
    );

    const blockSlotConflict =
      blockedSlotsAtLocation.find(
        // eslint-disable-next-line no-loop-func
        (slot) => areIntervalsOverlapping({ start: slot.start, end: slot.end }, { start, end })
      ) ||
      blockedSlotsOfManager.find(
        // eslint-disable-next-line no-loop-func
        (slot) => areIntervalsOverlapping({ start: slot.start, end: slot.end }, { start, end })
      );

    // eslint-disable-next-line no-loop-func
    const outlookCalendarConflict = outlookCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = dayjs(startDate).hour(startHours).minute(startMinutes).toDate();
      const eventEnd = dayjs(endDate).hour(endHours).minute(endMinutes).toDate();
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end });
    });

    // eslint-disable-next-line no-loop-func
    const googleCalendarConflict = googleCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = getDateWithCustomTime(startHours, startMinutes, 0, 0, startDate);
      const eventEnd = getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end });
    });

    const timeSlot = {
      start,
      startText: dayjs(start).format('hh:mm a'),
      end,
      endText: dayjs(end).format('hh:mm a')
    };

    if (
      !lunchConflict &&
      !bookingConflict &&
      !outlookCalendarConflict &&
      !googleCalendarConflict &&
      !blockSlotConflict &&
      dateIsValidAtLocation(date, timeSlot, location) &&
      !timeSlots.some((ts) => dayjs(ts.start).isSame(dayjs(timeSlot.start)))
    ) {
      timeSlots.push(timeSlot);
    }

    // get the next start time to check
    const { nextStartTime, nextIndexOfStartTimeToCheck } = getNextStartTimeToCheck({
      lunchConflict,
      lunchEndTime,
      end,
      startTimesToCheck,
      indexOfNextStartTimeToCheck
    });

    start = nextStartTime;
    end = dayjs(start).add(serviceDuration, 'minute');

    indexOfNextStartTimeToCheck = nextIndexOfStartTimeToCheck;
  }

  // sort the time slots
  timeSlots.sort((a, b) => (dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1));

  return timeSlots;
};

const getWorkdaysTextPerRRule = (rruleStr) => {
  let workdaysText = '';

  if (rruleStr && typeof rruleStr === 'string') {
    const rrule = RRule.fromString(rruleStr);

    try {
      const parsedRRule = rrule.toText();
      workdaysText = capitalizeFirstLetter(parsedRRule);
    } catch (error) {
      workdaysText = 'N/A';
    }
  }

  return workdaysText;
};

const getMobileServiceProviderTimeSlots = async ({
  date,
  service,
  selectedGroundZeroLocation,
  publicMode = false,
  ignoreGroundZeroLocation = false,
  timezonePerMobileBookingPlaceId,
  timeSlotInterval = 0
}) => {
  const timeSlots = [];

  if (!service || (!ignoreGroundZeroLocation && !selectedGroundZeroLocation) || !date) {
    return timeSlots;
  }

  const {
    workStartHours,
    workStartMinutes,
    workEndHours,
    workEndMinutes,
    lunchStartHours,
    lunchStartMinutes,
    lunchEndHours,
    lunchEndMinutes,
    timezone: groundZeroLocationTimezone
  } = selectedGroundZeroLocation;

  let timezone;

  let workStartTime = null;
  let workEndTime = null;
  let lunchStartTime = null;
  let lunchEndTime = null;

  if (ignoreGroundZeroLocation) {
    // ground zero location is only ignored in case of unconfirmed booking
    // in such case, the timezone as per mobile booking place id shall be used
    // the default work start time shall be 8:00 AM and ends at 5:00 PM
    // no lunch time is taken into account

    timezone = timezonePerMobileBookingPlaceId;
    workStartTime = getDateWithCustomTime(8, 0, 0, 0, date);
    workEndTime = getDateWithCustomTime(17, 0, 0, 0, date);
  } else {
    timezone = groundZeroLocationTimezone;

    if (validHours(workStartHours) && validMinutes(workStartMinutes) && validHours(workEndHours) && validMinutes(workEndMinutes)) {
      workStartTime = getDateWithCustomTime(workStartHours, workStartMinutes, 0, 0, date);
      workEndTime = getDateWithCustomTime(workEndHours, workEndMinutes, 0, 0, date);
    } else {
      return timeSlots;
    }

    if (validHours(lunchStartHours) && validMinutes(lunchStartMinutes) && validHours(lunchEndHours) && validMinutes(lunchEndMinutes)) {
      lunchStartTime = getDateWithCustomTime(lunchStartHours, lunchStartMinutes, 0, 0, date);
      lunchEndTime = getDateWithCustomTime(lunchEndHours, lunchEndMinutes, 0, 0, date);
    }
  }

  const serviceDuration = service.duration;

  if (!serviceDuration) {
    return timeSlots;
  }

  const requestForBookings = {
    url: publicMode
      ? `public/bookings/manager/${selectedGroundZeroLocation?.managerId}`
      : `bookings/manager/${selectedGroundZeroLocation?.managerId}`,
    method: 'get',
    params: {
      date: dayjs(date).format('YYYY-MM-DD'),
      excludeStatuses: [bookingStatuses.Canceled.value, bookingStatuses.DidNotAttend.value].join(',')
    }
  };

  // get all bookings for this date at this location
  const responseForBookings = publicMode ? await callAzureFunctionPublic(requestForBookings) : await callAzureFunction(requestForBookings);

  const requestForGoogleCalendarEvents = {
    url: publicMode ? `public/google-calendar/events` : `google-calendar/events`,
    method: 'get',
    params: {
      subject: selectedGroundZeroLocation?.emailAddress,
      timeMin: dayjs
        .tz(
          `${dayjs(date).format('YYYY-MM-DD')}T${String(ignoreGroundZeroLocation ? 8 : workStartHours).padStart(2, '0')}:${String(workStartMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeMax: dayjs
        .tz(
          `${dayjs(date).format('YYYY-MM-DD')}T${String(ignoreGroundZeroLocation ? 17 : workEndHours).padStart(2, '0')}:${String(workEndMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeZone: timezone
    }
  };

  const responseForGoogleCalendarEvents = publicMode
    ? await callAzureFunctionPublic(requestForGoogleCalendarEvents)
    : await callAzureFunction(requestForGoogleCalendarEvents);

  const mspEmailAddress = selectedGroundZeroLocation?.emailAddress;

  const bookings = responseForBookings.data;
  const googleCalendarEvents = responseForGoogleCalendarEvents.data;
  const outlookCalendarEvents = await getOutlookCalendarEventsOfUser({
    userPrincipalName: mspEmailAddress,
    bookingDate: date,
    managerId: selectedGroundZeroLocation?.managerId,
    timezone,
    publicMode
  });

  const unavailableBookingSlots = bookings?.map((booking) => {
    const { startHours, startMinutes, endHours, endMinutes, isMobileBooking, travelTimeBufferMinutes } = booking;

    let unavailableSlot = {
      start: getDateWithCustomTime(startHours, startMinutes, 0, 0, date),
      end: getDateWithCustomTime(endHours, endMinutes, 0, 0, date)
    };

    if (isMobileBooking) {
      const startDateObj = dayjs(getDateWithCustomTime(startHours, startMinutes, 0, 0, date)).subtract(
        Number(travelTimeBufferMinutes || 0),
        'minute'
      );

      const endDateObj = dayjs(getDateWithCustomTime(endHours, endMinutes, 0, 0, date)).add(Number(travelTimeBufferMinutes || 0), 'minute');

      unavailableSlot = {
        start: startDateObj,
        end: endDateObj
      };
    }

    return unavailableSlot;
  });

  // get all end times of existing bookings
  const allBookingEndTimes = unavailableBookingSlots.map((booking) => booking.end);

  const allGoogleEventEndTimes = googleCalendarEvents.map((event) => {
    const { endDate, endHours, endMinutes } = event;
    return getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
  });

  const allOutlookEventEndTimes = outlookCalendarEvents.map((event) => {
    const { endDate, endHours, endMinutes } = event;
    return getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
  });

  const allTimeSlotIntervalStartTimes = getAllTimeSlotIntervalStartTimes(workStartTime, workEndTime, timeSlotInterval);

  const startTimesToCheck = [
    ...new Set([...allTimeSlotIntervalStartTimes, ...allBookingEndTimes, ...allGoogleEventEndTimes, ...allOutlookEventEndTimes])
  ];

  // start and end time for time slot
  let start = workStartTime;
  let end = dayjs(start).add(serviceDuration, 'minute');

  let indexOfNextStartTimeToCheck = 0;
  startTimesToCheck.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 1));

  while (start >= workStartTime && start < workEndTime && end > workStartTime && end <= workEndTime) {
    // check for conflicts with lunch time
    const lunchConflict =
      lunchStartTime && lunchEndTime && areIntervalsOverlapping({ start: lunchStartTime, end: lunchEndTime }, { start, end });
    // check for conflicts with at least one exisiting booking
    const bookingConflict = unavailableBookingSlots.find(
      // eslint-disable-next-line no-loop-func
      (slot) => areIntervalsOverlapping({ start: slot.start, end: slot.end }, { start, end })
    );
    // check for conflicts with google calendar events
    // eslint-disable-next-line no-loop-func
    const googleCalendarConflict = googleCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = getDateWithCustomTime(startHours, startMinutes, 0, 0, startDate);
      const eventEnd = getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end });
    });

    // eslint-disable-next-line no-loop-func
    const outlookCalendarConflict = outlookCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = getDateWithCustomTime(startHours, startMinutes, 0, 0, startDate);
      const eventEnd = getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end });
    });

    const timeSlot = {
      start,
      startText: dayjs(start).format('hh:mm a'),
      end,
      endText: dayjs(end).format('hh:mm a')
    };

    if (
      !lunchConflict &&
      !bookingConflict &&
      !googleCalendarConflict &&
      !outlookCalendarConflict &&
      !timeSlots.some((ts) => dayjs(ts.start).isSame(dayjs(timeSlot.start))) &&
      (ignoreGroundZeroLocation
        ? dateIsValidAsPerTimezone(date, timeSlot, timezone)
        : dateIsValidAtLocation(date, timeSlot, selectedGroundZeroLocation))
    ) {
      timeSlots.push(timeSlot);
    }

    // get the next start time to check
    const { nextStartTime, nextIndexOfStartTimeToCheck } = getNextStartTimeToCheck({
      lunchConflict,
      lunchEndTime,
      end,
      startTimesToCheck,
      indexOfNextStartTimeToCheck
    });

    start = nextStartTime;
    end = dayjs(start).add(serviceDuration, 'minute');

    indexOfNextStartTimeToCheck = nextIndexOfStartTimeToCheck;
  }

  // sort the time slots
  timeSlots.sort((a, b) => (dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1));

  return timeSlots;
};

/**
 * Calculates the time slot interval for a booking page at a given location.
 * @param {object} bookingPage - Booking page object
 * @param {object} location - Location object
 * @param {number?} defaultTimeSlotInterval - Default time slot interval per app config
 * @returns {number} Time slot interval in minutes
 */

const getTimeSlotInterval = (bookingPage, location, defaultTimeSlotInterval = null) => {
  if (!location || !bookingPage) {
    return 0; // Fallback when necessary parameters are missing
  }

  const getShortestServiceDuration = () => {
    let timeSlotInterval = 0;

    bookingPage.Services?.forEach((service) => {
      if (service.Locations.some((loc) => loc.id === location.id)) {
        if (timeSlotInterval === 0 || service.duration < timeSlotInterval) {
          timeSlotInterval = service.duration;
        }
      }
    });

    return timeSlotInterval;
  };

  // Use location's interval if valid and not 0
  if (location.timeSlotInterval != null && location.timeSlotInterval !== 0) {
    return location.timeSlotInterval;
  }

  // Handle specific defaultTimeSlotInterval cases
  if (defaultTimeSlotInterval === null || defaultTimeSlotInterval === '0') {
    // If default is null or '0', return shortest service duration
    return getShortestServiceDuration();
  }

  // Fallback to defaultTimeSlotInterval
  return defaultTimeSlotInterval;
};

/**
 * Gets a booking creation origin, i.e. where a booking was created from.
 * @param {*} booking Booking object
 * @returns creation origin string
 */
const getCreatedFrom = (booking = {}) => {
  const { adminCreated, managerCreated, bookingPageId, respondentAvailabilityIsKnown, isMobileBooking } = booking;
  const createdByAdminOrManager = Boolean(adminCreated || managerCreated);
  const fromBookingPage = Boolean(bookingPageId);

  // booking created from booking page
  if (createdByAdminOrManager && fromBookingPage) {
    return 'Booking Page';
  }

  // booking created from public booking page
  if (!createdByAdminOrManager && fromBookingPage && isMobileBooking && !respondentAvailabilityIsKnown) {
    return 'Public Booking Page - Get Help From Admin';
  }

  // booking created from public booking page
  if (!createdByAdminOrManager && fromBookingPage) {
    return 'Public Booking Page';
  }
  // booking created from calendar
  if (createdByAdminOrManager && !fromBookingPage) {
    return 'Calendar';
  }

  return '';
};

const getPreferredTimeFrameLabel = (preferredTimeFrame) => {
  if (!preferredTimeFrame) {
    return '-';
  }

  const preferredTimeFrameOption = preferredTimeFrameOptions.find((option) => option.value === preferredTimeFrame);

  return preferredTimeFrameOption ? preferredTimeFrameOption.label : '-';
};

const isDateRangeOverlap = (range1, range2) => {
  // Destructure start and end dates from both ranges
  const { start: start1, end: end1 } = range1;
  const { start: start2, end: end2 } = range2;

  // Check if the ranges overlap
  return dayjs(start1).isBefore(end2) && dayjs(start2).isBefore(end1);
};

/**
 * Check wether selected slot overlaps to any blocked slot of selected Location
 * @param {object[]} locationBlockedSlots Array of blocked slots from every selected Location
 * @param {object} slotInfo Selected slot details
 * @param {string} mode Calendar mode
 * @param {object} selectedLocationOnLocationAndManagerMode Selected Location on ByLocationAndManager mode
 * @returns {boolean} return `true` if  selected slot overlaps to any blocked slot of Locations. otherwise, false
 */
const locationBlockSlotCollision = (locationBlockedSlots, slotInfo, mode, selectedLocationOnLocationAndManagerMode) => {
  let locationIdToMatch = null;

  if (mode === calendarModes.ByLocation) {
    locationIdToMatch = slotInfo.resourceId;
  } else if (mode === calendarModes.ByLocationAndManager) {
    locationIdToMatch = selectedLocationOnLocationAndManagerMode.id;
  }

  const isSlotBlocked = locationBlockedSlots.some((item) => {
    const range1 = {
      start: slotInfo.start,
      end: slotInfo.end
    };
    const range2 = {
      start: dayjs(item.date).set('hour', item.startHours).set('minute', item.startMinutes),
      end: dayjs(item.date).set('hour', item.endHours).set('minute', item.endMinutes)
    };

    const isSelectedSlotOverlapsToBlockedSlot = Boolean(
      isDateRangeOverlap(range1, range2) && item.isBlockSlot && item.locationId === locationIdToMatch
    );

    const isSelectedSlotOverlapsToAlldayBlockedSlot = Boolean(
      item.isBlockSlot &&
        item.allDay &&
        dayjs(item.date).format('YYYY-MM-DD') === dayjs(slotInfo.start).format('YYYY-MM-DD') &&
        item.locationId === locationIdToMatch
    );

    return isSelectedSlotOverlapsToBlockedSlot || isSelectedSlotOverlapsToAlldayBlockedSlot;
  });
  return isSlotBlocked || false;
};

/**
 * Check whether selected slot overlaps to any blocked slot of selected Manager
 * @param {object[]} managerBlockedSlots Array of blocked slots from every selected Manager
 * @param {object} slotInfo Selected slot details
 * @returns {boolean} return `true` if  selected slot overlaps to any blocked slot of selected Manager. otherwise, false
 */
const managerBlockSlotCollision = (managerBlockedSlots, slotInfo) => {
  const selectedManagerId = slotInfo.resourceId;

  const isSlotBlocked = managerBlockedSlots.some((item) => {
    const range1 = {
      start: slotInfo.start,
      end: slotInfo.end
    };
    const range2 = {
      start: dayjs(item.date).set('hour', item.startHours).set('minute', item.startMinutes),
      end: dayjs(item.date).set('hour', item.endHours).set('minute', item.endMinutes)
    };

    const isSelectedSlotOverlapsToBlockedSlot = Boolean(
      isDateRangeOverlap(range1, range2) && item.isBlockSlot && item.managerId === selectedManagerId
    );

    const isSelectedSlotOverlapsToAlldayBlockedSlot = Boolean(
      item.isBlockSlot &&
        item.allDay &&
        dayjs(item.date).format('YYYY-MM-DD') === dayjs(slotInfo.start).format('YYYY-MM-DD') &&
        item.managerId === selectedManagerId
    );

    return isSelectedSlotOverlapsToBlockedSlot || isSelectedSlotOverlapsToAlldayBlockedSlot;
  });
  return isSlotBlocked || false;
};

const defaultWorkTimeSchedules = {
  workStartTime: getDateWithCustomTime(8, 0, 0, 0),
  workEndTime: getDateWithCustomTime(17, 0, 0, 0),
  lunchStartTime: getDateWithCustomTime(12, 0, 0, 0),
  lunchEndTime: getDateWithCustomTime(13, 0, 0, 0)
};

/**
 * Check if current values are different from cached values
 * @param {object[]} current - current values
 * @param {object[]} cached - cached values
 * @returns {boolean} true if current values are different from cached values, otherwise false
 */

const haveValuesChanged = (current, cached) => {
  let answer = false;

  if (current.length !== cached.length) answer = true;

  if (!current.every((option) => cached.map((each) => each.id).includes(option.id))) return true;

  return answer;
};

export {
  getDateWithCustomTime,
  validHours,
  validMinutes,
  dayIsValidWorkday,
  dayIsUnavailable,
  dateIsValidAtLocation,
  dateIsValidPerManagerSchedules,
  getEarliestAvailableDate,
  getAvailableTimeSlots,
  getCreatedFrom,
  getMobileServiceProviderTimeSlots,
  getAllWorkDays,
  getSelectedGZLocationPerRRule,
  getWorkdaysTextPerRRule,
  dateIsValidPerRRULE,
  dateIsValidPerRRulesOfAllGZLocations,
  defaultWorkTimeSchedules,
  hasNoLunchTime,
  getPreferredTimeFrameLabel,
  getEarliestDateForGZLocation,
  haveValuesChanged,
  getTimeSlotInterval,
  dayIsBlockedAtLocation,
  dayIsBlockedOnManager,
  isDateRangeOverlap,
  locationBlockSlotCollision,
  managerBlockSlotCollision,
  createDayjsTime
};
