const AppConfigTypes = {
  STRING: 'string',
  BOOLEAN: 'boolean',
  IMAGE: 'image',
  INTEGER: 'integer',
  INTEGER_OPTIONS: 'integer-options'
};

const appConfigMeta = {
  generalSettings: {
    sendBySmsEnabled: {
      value: false,
      title: 'Sending by SMS',
      description: "Allows SMS's to be sent by the app.",
      type: AppConfigTypes.BOOLEAN
    }
  },
  entities: {
    absence: {
      value: 'Absence',
      title: 'Absence',
      description: `The text entity 'Absence' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    absences: {
      value: 'Absences',
      title: 'Absences',
      description: `The text entity 'Absences' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    absenceManagement: {
      value: 'Absence Management',
      title: 'Absence Management',
      description: `The text entity 'Absence Management' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    admin: {
      value: 'Admin',
      title: 'Admin',
      description: `The text entity 'Admin' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    admins: {
      value: 'Admins',
      title: 'Admins',
      description: `The text entity 'Admins' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    booking: {
      value: 'Booking',
      title: 'Booking',
      description: `The text entity 'Booking' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    bookings: {
      value: 'Bookings',
      title: 'Bookings',
      description: `The text entity 'Bookings' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    bookingPage: {
      value: 'Booking Page',
      title: 'Booking Page',
      description: `The text entity 'Booking Page' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    bookingPages: {
      value: 'Booking Pages',
      title: 'Booking Pages',
      description: `The text entity 'Booking Pages' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    externalUser: {
      value: 'External User',
      title: 'External User',
      description: `The text entity 'External User' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    externalUsers: {
      value: 'External Users',
      title: 'External Users',
      description: `The text entity 'External Users' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    manager: {
      value: 'Manager',
      title: 'Manager',
      description: `The text entity 'Manager' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    managers: {
      value: 'Managers',
      title: 'Managers',
      description: `The text entity 'Managers' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    respondent: {
      value: 'Respondent',
      title: 'Respondent',
      description: `The text entity 'Respondent' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    respondents: {
      value: 'Respondents',
      title: 'Respondents',
      description: `The text entity 'Respondents' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    survey: {
      value: 'Survey',
      title: 'Survey',
      description: `The text entity 'Survey' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    surveys: {
      value: 'Surveys',
      title: 'Surveys',
      description: `The text entity 'Surveys' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    surveyResponse: {
      value: 'Survey Response',
      title: 'Survey Response',
      description: `The text entity 'Survey Response' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    surveyResponses: {
      value: 'Survey Responses',
      title: 'Survey Responses',
      description: `The text entity 'Survey Responses' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    surveyType: {
      value: 'Survey Type',
      title: 'Survey Type',
      description: `The text entity 'Survey Type' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    },
    surveyTypes: {
      value: 'Survey Types',
      title: 'Survey Types',
      description: `The text entity 'Survey Types' will be replaced throughout the app with this custom name.`,
      type: AppConfigTypes.STRING
    }
  },
  respondents: {
    respondentStatus: {
      value: false,
      title: 'Respondent Status',
      description: 'Allow respondents to have status indicators.',
      type: AppConfigTypes.BOOLEAN
    },
    requireContactMobile: {
      value: false,
      title: 'Require Contact Mobile',
      description: 'Make the contact mobile field of respondents mandatory.',
      type: AppConfigTypes.BOOLEAN
    },
    custom01: {
      value: '',
      title: 'Custom Field 1',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom02: {
      value: '',
      title: 'Custom Field 2',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom03: {
      value: '',
      title: 'Custom Field 3',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom04: {
      value: '',
      title: 'Custom Field 4',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom05: {
      value: '',
      title: 'Custom Field 5',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom06: {
      value: '',
      title: 'Custom Field 6',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom07: {
      value: '',
      title: 'Custom Field 7',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom08: {
      value: '',
      title: 'Custom Field 8',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom09: {
      value: '',
      title: 'Custom Field 9',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    },
    custom10: {
      value: '',
      title: 'Custom Field 10',
      description:
        'Set the name for a custom field for respondents. Any fields with a name value will be in use, to disable the field, set the name to be empty. These fields can be set by an admin, or can also be set by answering a survey question with the relevant property configured.',
      type: AppConfigTypes.STRING
    }
  },
  images: {
    favicon: {
      value: '',
      title: 'Favicon',
      description: 'Favicon image. This image is shown in the browser tab at the top of the browser.',
      type: AppConfigTypes.IMAGE
    },
    sidebarLogo: {
      value: '',
      title: 'Sidebar logo',
      description: 'Logo for the sidebar. This image is shown above the sidebar on the left side of the website.',
      type: AppConfigTypes.IMAGE
    },
    tenantLogo: {
      value: '',
      title: 'Tenant logo',
      description: 'Logo for the tenant. This image is shown on the login page, as well as redirect screens that lead to the login page.',
      type: AppConfigTypes.IMAGE
    },
    pdfHeader: {
      value: '',
      title: 'PDF header',
      description: 'Header image for the PDF. This image is shown at the top of each page when a PDF is generated.',
      type: AppConfigTypes.IMAGE
    },
    defaultSurveySplash: {
      value: '',
      title: 'Default survey splash',
      description:
        'Default splash image for surveys. This image is automatically added to the survey editor when a new survey is created, however it may be removed via the editor also.',
      type: AppConfigTypes.IMAGE
    },
    emailConfirmationLogo: {
      value: '',
      title: 'Email confirmation logo',
      description:
        'Logo for email confirmation. This image will be shown in any emails sent out when an email is created, updated, or cancelled, if the {logo} replacement string is included in the Confirmation Text editor.',
      type: AppConfigTypes.IMAGE
    }
  },
  bookings: {
    disableAnyManagerAtLocation: {
      value: false,
      title: `Disables the 'Any manager at location' option in bookings pages`,
      description: `When enabled, booking pages will not allow the person completing them to select the option 'Any manager at location', they will be required to select a specific manager`,
      type: AppConfigTypes.BOOLEAN
    },
    allowAffiliate: {
      value: false,
      title: 'Allow Affiliate Bookings',
      description: `When enabled, Booking Pages can be configured to allow selecting an affiliate location. If this option is then selected when making a booking via the booking page, the user may only select a service, date, time, and complete the booking and service survey if present. Affiliate Bookings will be displayed in a separate tab of the Bookings Table visible only to admins, and can be marked as either Unconfirmed or Confirmed.`,
      type: AppConfigTypes.BOOLEAN
    },
    bookingConfirmationReceiver: {
      value: '',
      title: 'Booking confirmation receiver',
      description: `Each time a booking is made, the email address entered in this field will receive an email with the booking's information.`,
      type: AppConfigTypes.STRING
    },
    bookingContactNumber: {
      value: '',
      title: 'Contact Number',
      description: `Shows on the public booking pages where the error message requires a contact number to be shown to the user in order to help them complete the booking.`,
      type: AppConfigTypes.STRING
    },
    enableMobileBookingFeature: {
      value: false,
      title: 'Enable Mobile Booking Feature',
      description: `When enabled, Booking Pages can be configured to facilitate mobile bookings. Mobile bookings require setting up mobile service providers (MSP), a variant of manager that can be created/ set up by an admin in the Manager profile `,
      type: AppConfigTypes.BOOLEAN
    },
    showMSPsTravelDurationAndDistance: {
      value: false,
      title: 'Show MSP Travel Duration and Distance',
      description: `When enabled, the travel duration and distance of available MSPs to a selected location are shown in mobile booking pages. Only applicable if Mobile Booking Feature is enabled. `,
      type: AppConfigTypes.BOOLEAN
    },
    automatedBookingReminder: {
      value: false,
      title: 'Automated booking reminder',
      description: `Sends an automatic reminder email to respondents prior to their appointment. Will also send an sms if the respondent has a contact mobile phone, and the general setting 'Sending by SMS' is enabled.`,
      type: AppConfigTypes.BOOLEAN
    },
    managerRecieveBookingConfirmationText: {
      value: false,
      title: 'Manager recieves a copy of respondent booking confirmation email',
      description: `When enabled, The Manager will get a copy of email that the respondent recieves when the booking is placed.`,
      type: AppConfigTypes.BOOLEAN
    },
    hoursBeforeBookingReminder: {
      value: 3,
      title: 'Hours before booking to send reminder',
      description: `If automated booking reminder is enabled, sets how many hours before the appointment the reminder should be sent. Default value is 3 hours.`,
      type: AppConfigTypes.INTEGER,
      props: {
        min: 1,
        max: 72,
        allowDecimal: false
      }
    },
    postCompletionText: {
      value: 'Thank you for making a booking.',
      title: 'Post completion message for booking pages',
      description: `Set a message that will be displayed to users after they successfully place a booking from the booking page.`,
      type: AppConfigTypes.STRING,
      props: {
        multiline: true
      }
    },
    enableTimeSlotInterval: {
      value: false,
      title: 'Enable Time Slot Interval Feature',
      description: `When enabled, time slots are generated on a specific interval in the Step 3: Date and Time of the booking page. The interval can be set by location via Location Form. `,
      type: AppConfigTypes.BOOLEAN
    },
    defaultTimeSlotInterval: {
      value: null,
      title: 'Default Time Slot Interval',
      description: `When this feature is enabled, it sets a default time slot interval (in minutes) to be used across all locations. For example, an interval of 15 minutes means that time slots will be available every 15 minutes (e.g., 10:00, 10:15, 10:30). Alternatively, the interval can be configured to match the duration of the shortest service available at the location.`,
      type: AppConfigTypes.INTEGER_OPTIONS
    }
  },
  surveys: {
    sentimentAnalysis: {
      value: false,
      title: 'Sentiment Analysis',
      description:
        'Completed surveys will be assessed by an AI to provide a brief paragraph analysing the survey response, and if the sentiment of the response is neutral, negative, or positive',
      type: AppConfigTypes.BOOLEAN
    },
    introScreen: {
      value: false,
      title: 'Intro screen',
      description:
        'Show intro screen before survey. The intro screen prompts managers to hand their device to a respondent to fill the survey in.',
      type: AppConfigTypes.BOOLEAN
    },
    outroScreen: {
      value: false,
      title: 'Outro screen',
      description:
        'Show outro screen after survey. The outro screen prompts respondents filling out a survey on a managers device to return the device once the survey is completed.',
      type: AppConfigTypes.BOOLEAN
    },
    backgrounds: {
      value: false,
      title: 'Backgrounds',
      description: 'Allow survey respondents to select a survey background image.',
      type: AppConfigTypes.BOOLEAN
    },
    themes: {
      value: false,
      title: 'Themes',
      description: 'Allow survey respondents to select a survey theme',
      type: AppConfigTypes.BOOLEAN
    },
    games: {
      value: false,
      title: 'Games',
      description: 'Allow survey respondents to play games while answering surveys',
      type: AppConfigTypes.BOOLEAN
    },
    textToSpeech: {
      value: true,
      title: 'Text-to-speech',
      description: 'Allows survey respondents to listen to survey questions via text to speech.',
      type: AppConfigTypes.BOOLEAN
    },
    speechToText: {
      value: true,
      title: 'Speech-to-text',
      description: 'Allows survey respondents to answer survey questions via speech to text.',
      type: AppConfigTypes.BOOLEAN
    },
    pdfCoverPage: {
      value: false,
      title: 'PDF cover page',
      description: 'Show cover page on Survey PDF.',
      type: AppConfigTypes.BOOLEAN
    }
  },
  bookingConfirmationText: {
    emailSubject: {
      value: `Booking No. {bookingId} has been scheduled for {firstName} {lastName}`,
      title: 'Email subject',
      description: 'Subject for the confirmation email.',
      type: AppConfigTypes.STRING
    },
    emailBody: {
      value: `
        <p>Hi {firstName} {lastName},</p>
        <p>Here are your <strong>Appointment Details:</strong></p>
        <br>
        <p>BookingID: {bookingId}</p>
        <p>Date: {appointmentDate}</p>
        <p>Time: {appointmentStartTime}</p>
        <p>Service Provider: {providerFirstName} {providerLastName}</p>
        <p>Service: {serviceName}</p>
        <p>{serviceInstructions}</p>
        <br>
        <p>Your appointment is at {locationName} {locationAddress}.</p>
        <p>{locationNotes}<br><br>Please click the link below to answer these important questions before your appointment.</p>
        <p>{surveyLink}</p>
        <br>
        <p>Please call us on 📞 <strong>+61 431 251 751 </strong>if you need to rebook or cancel.</p>
      `,
      title: 'Email body',
      description: 'Body text for the confirmation email.',
      type: AppConfigTypes.STRING
    },
    smsBody: {
      value: `
        Hi {firstName} {lastName},\n
        Here are your Appointment Details:\n\n
        BookingID: {bookingId}\n
        Date: {appointmentDate}\n
        Time: {appointmentStartTime}\n
        Service Provider: {providerFirstName} {providerLastName}\n
        Service: {serviceName}\n
        {serviceInstructions}\n\n
        Your appointment is at {locationName} {locationAddress}.\n
        {locationNotes}\n\n
        Please click the link below to answer these important questions before your appointment.
        \n{surveyLink}\n
      `,
      title: 'SMS body',
      description: 'Body text for the confirmation SMS.',
      type: AppConfigTypes.STRING
    }
  },
  bookingAffiliateConfirmationText: {
    emailSubject: {
      value: `Affiliate Booking No. {bookingId} has been requested for {firstName} {lastName}`,
      title: 'Email subject',
      description: 'Subject for the affiliate confirmation email.',
      type: AppConfigTypes.STRING
    },
    emailBody: {
      value: `
        <p>Hi {firstName} {lastName},</p>
        <p>Your booking has been requested, we will be in contact to confirm your details.</p>
        <br>
        <p>BookingID: {bookingId}</p>
        <p>Date: {appointmentDate}</p>
        <p>Time: {appointmentStartTime}</p>
        <p>Service: {serviceName}</p>
        <p>{serviceInstructions}</p>
        <br>
        <p>Your appointment is at {locationName}.</p>
        <p>Please click the link below to answer these important questions before your appointment.</p>
        <p>{surveyLink}</p>
        <br>
        <p>Please call us on 📞 <strong>+61 431 251 751 </strong>if you need to rebook or cancel.</p>
      `,
      title: 'Email body',
      description: 'Body text for the affiliate confirmation email.',
      type: AppConfigTypes.STRING
    },
    smsBody: {
      value: `
        Hi {firstName} {lastName},\n
        Your booking has been requested, we will be in contact to confirm your details.\n\n
        BookingID: {bookingId}\n
        Date: {appointmentDate}\n
        Time: {appointmentStartTime}\n
        Service: {serviceName}\n

        {serviceInstructions}\n\n

        Please click the link below to answer these important questions before your appointment.
        \n{surveyLink}\n
      `,
      title: 'SMS body',
      description: 'Body text for the confirmation SMS.',
      type: AppConfigTypes.STRING
    }
  },
  cancelledBookingText: {
    emailSubject: {
      value: `Booking No. {bookingId} has been canceled for {firstName} {lastName}`,
      title: 'Email subject',
      description: 'Subject for the cancellation email.',
      type: AppConfigTypes.STRING
    },
    emailBody: {
      value: `
        <p>Hi {firstName} {lastName},</p>
        <p>We regret to inform you that your appointment has been canceled. Here are the details:</p>
        <p><strong>Appointment Details:</strong></p>
        <p>Booking ID: {bookingId}</p>
        <p>Date: {appointmentDate}</p>
        <p>Time: {appointmentStartTime}</p>
        <p>Service Provider: {providerFirstName} {providerLastName}</p>
        <p>Service: {serviceName}</p>
        <p>{serviceInstructions}</p>
        <p>Your appointment was originally scheduled at {locationName} {locationAddress}.</p>
        <p>{locationNotes}</p>

        <p>Please note that your appointment has been canceled. If you have any questions or concerns, feel free to contact us. We apologize for any inconvenience this may have caused.</p>

        <p>If you need to rebook or have further inquiries, please call us at 📞 <strong>+61 431 251 751</strong>.</p>

        <p>Thank you for your understanding.</p>
      `,
      title: 'Email body',
      description: 'Body text for the cancellation email.',
      type: AppConfigTypes.STRING
    },
    smsBody: {
      value: `
        Hi {firstName} {lastName},
        We regret to inform you that your appointment has been canceled.
        Here are the details:\n
        \nAppointment Details:\n
        \nBooking ID: {bookingId}\n
        Date: {appointmentDate}\n
        Time: {appointmentStartTime}\n
        Service Provider: {providerFirstName} {providerLastName}\n
        Service: {serviceName}\n
        {serviceInstructions}\n
        \nYour appointment was originally scheduled at {locationName} {locationAddress}.\n
        {locationNotes}\n\n If you have any questions or concerns, please contact us. Apologies for any inconvenience.
        \n\nPlease call us at +61 431 251 751 for rebooking or further assistance.
        \n\nThank you for your understanding.
      `,
      title: 'SMS body',
      description: 'Body text for the cancellation SMS.',
      type: AppConfigTypes.STRING
    }
  },
  didNotAttendBookingText: {
    emailSubject: {
      value: `Booking No. {bookingId} has been tagged as DNA for {firstName} {lastName}`,
      title: 'Email subject',
      description: 'Subject for the did-not-attend (DNA) booking email.',
      type: AppConfigTypes.STRING
    },
    emailBody: {
      value: `
        <p>Hi {firstName} {lastName},</p>
        <p>This is to inform you that you have failed to attend a scheduled booking. Here are the details:</p>
        <p><strong>Appointment Details:</strong></p>
        <p>Booking ID: {bookingId}</p>
        <p>Date: {appointmentDate}</p>
        <p>Time: {appointmentStartTime}</p>
        <p>Service Provider: {providerFirstName} {providerLastName}</p>
        <p>Service: {serviceName}</p>
        <p>{serviceInstructions}</p>
        <p>Your appointment was originally scheduled at {locationName} {locationAddress}.</p>
        <p>{locationNotes}</p>

        <p>If you have any questions or concerns, feel free to contact us. We apologize for any inconvenience this may have caused.</p>

        <p>If you need to rebook or have further inquiries, please call us at 📞 <strong>+61 431 251 751</strong>.</p>

        <p>Thank you for your understanding.</p>
      `,
      title: 'Email body',
      description: 'Body text for the DNA booking email.',
      type: AppConfigTypes.STRING
    },
    smsBody: {
      value: `
        Hi {firstName} {lastName},
        This is to inform you that you have failed to attend a scheduled booking.
        Here are the details:\n
        \nAppointment Details:\n
        \nBooking ID: {bookingId}\n
        Date: {appointmentDate}\n
        Time: {appointmentStartTime}\n
        Service Provider: {providerFirstName} {providerLastName}\n
        Service: {serviceName}\n
        {serviceInstructions}\n
        \nYour appointment was originally scheduled at {locationName} {locationAddress}.\n
        {locationNotes}\n\n If you have any questions or concerns, please contact us. Apologies for any inconvenience.
        \n\nPlease call us at +61 431 251 751 for rebooking or further assistance.
        \n\nThank you for your understanding.
      `,
      title: 'SMS body',
      description: 'Body text for the DNA booking SMS.',
      type: AppConfigTypes.STRING
    }
  },
  rescheduledBookingText: {
    emailSubject: {
      value: `Booking No. {bookingId} has been rescheduled for {firstName} {lastName}`,
      title: 'Email subject',
      description: 'Subject for the rescheduled booking email.',
      type: AppConfigTypes.STRING
    },
    emailBody: {
      value: `
        <p>Hi {firstName} {lastName},</p>
        <p>This is to inform you about some changes to your scheduled booking. Below are the updated details:</p>
        <p><strong>Appointment Details:</strong></p>
        <p>Booking ID: {bookingId}</p>
        <p>Date: {appointmentDate}</p>
        <p>Time: {appointmentStartTime}</p>
        <p>Service Provider: {providerFirstName} {providerLastName}</p>
        <p>Service: {serviceName}</p>
        <p>{serviceInstructions}</p>
        <p>Your appointment is scheduled at {locationName} {locationAddress}.</p>
        <p>{locationNotes}</p>

        <p>If you have any questions or concerns, feel free to contact us. We apologize for any inconvenience this may have caused.</p>

        <p>If you need to rebook or have further inquiries, please call us at 📞 <strong>+61 431 251 751</strong>.</p>

        <p>Thank you for your understanding.</p>
      `,
      title: 'Email body',
      description: 'Body text for the rescheduled booking email.',
      type: AppConfigTypes.STRING
    },
    smsBody: {
      value: `
        Hi {firstName} {lastName},
        This is to inform you about some changes to your scheduled booking.
        Below are the updated details:\n
        \nAppointment Details:\n
        \nBooking ID: {bookingId}\n
        Date: {appointmentDate}\n
        Time: {appointmentStartTime}\n
        Service Provider: {providerFirstName} {providerLastName}\n
        Service: {serviceName}\n
        {serviceInstructions}\n
        \nYour appointment is scheduled at {locationName} {locationAddress}.\n
        {locationNotes}\n\n If you have any questions or concerns, please contact us. Apologies for any inconvenience.
        \n\nPlease call us at +61 431 251 751 for rebooking or further assistance.
        \n\nThank you for your understanding.
      `,
      title: 'SMS body',
      description: 'Body text for the rescheduled booking SMS.',
      type: AppConfigTypes.STRING
    }
  },
  reminderBookingText: {
    emailSubject: {
      value: `Reminder for Booking No. {bookingId} for {firstName} {lastName}`,
      title: 'Email subject',
      description: 'Subject for the reminder booking email.',
      type: AppConfigTypes.STRING
    },
    emailBody: {
      value: `
        <p>Hi {firstName} {lastName},</p>
        <p>This is a gentle reminder for your scheduled booking. Here are the details:</p>
        <p><strong>Appointment Details:</strong></p>
        <p>Booking ID: {bookingId}</p>
        <p>Date: {appointmentDate}</p>
        <p>Time: {appointmentStartTime}</p>
        <p>Service Provider: {providerFirstName} {providerLastName}</p>
        <p>Service: {serviceName}</p>
        <p>{serviceInstructions}</p>
        <p>Your appointment is scheduled at {locationName} {locationAddress}.</p>
        <p>{locationNotes}</p>

        <p>If you have any questions or concerns, feel free to contact us. We apologize for any inconvenience this may have caused.</p>

        <p>If you need to rebook or have further inquiries, please call us at 📞 <strong>+61 431 251 751</strong>.</p>

        <p>Thank you for your understanding.</p>
      `,
      title: 'Email body',
      description: 'Body text for the reminder booking email.',
      type: AppConfigTypes.STRING
    },
    smsBody: {
      value: `
        Hi {firstName} {lastName},
        This is a gentle reminder for your scheduled booking.
        Here are the details:\n
        \nAppointment Details:\n
        \nBooking ID: {bookingId}\n
        Date: {appointmentDate}\n
        Time: {appointmentStartTime}\n
        Service Provider: {providerFirstName} {providerLastName}\n
        Service: {serviceName}\n
        {serviceInstructions}\n
        \nYour appointment is scheduled at {locationName} {locationAddress}.\n
        {locationNotes}\n\n If you have any questions or concerns, please contact us. Apologies for any inconvenience.
        \n\nPlease call us at +61 431 251 751 for rebooking or further assistance.
        \n\nThank you for your understanding.
      `,
      title: 'SMS body',
      description: 'Body text for the DNA booking SMS.',
      type: AppConfigTypes.STRING
    }
  },
  helpText: {
    mobileBookingHelpDialogText: {
      value: `Please provide your personal information and preferred appointment details.`,
      type: AppConfigTypes.STRING
    },
    respondentFormHelpDialogText: {
      value: `If you are completing this form on behalf of another person, please enter their details, not your own.`,
      type: AppConfigTypes.STRING
    }
  },
  others: {
    respondentDevelopmentalAge: {
      value: false,
      title: 'Respondent developmental age',
      description: 'Adds the developmental age field to respondents.',
      type: AppConfigTypes.BOOLEAN
    },
    anonymousSurveys: {
      value: true,
      title: 'Anonymous surveys',
      description: 'Allows answering public surveys anonymously.',
      type: AppConfigTypes.BOOLEAN
    },
    absenceManagement: {
      value: false,
      title: 'Absence management',
      description:
        "Allows usage of absence management survey type form, which can be used to track absence related information based on the respondent's answer .",
      type: AppConfigTypes.BOOLEAN
    },
    companies: {
      value: false,
      title: 'Companies',
      description:
        "Enables access, as well as the creation and maintenance of company records. Companies can be assigned to booking pages, respondents, and managers. This assignment allows managers to access respondents if the respondent's current company is the same as the company assigned to the manager. Also, booking pages assigned to a company will automatically associate the bookings to the same company.",
      type: AppConfigTypes.BOOLEAN
    }
  },
  integrations: {
    iinsight: {
      enableIinsightCaseNumQuery: {
        value: false,
        title: 'Enable iinsight integration',
        description: `This allows associating a booking with an iinsight case id's when creating a booking, and retrieving information from the iinsight case.`,
        type: AppConfigTypes.BOOLEAN
      }
    },
    googleCalendar: {
      calendarApiRead: {
        value: false,
        title: 'Enable read access to Calendar API',
        description: `This enables read access to calendars of users belonging to your Google organization or workspace.`,
        type: AppConfigTypes.BOOLEAN
      },
      calendarApiWrite: {
        value: false,
        title: 'Enable read and write access to Calendar API',
        description: `This enables read and write access to calendars of users belonging to your Google organization or workspace.`,
        type: AppConfigTypes.BOOLEAN
      },
      calendarApiTestEmail: {
        value: '',
        title: 'Calendar API test email',
        description: `An email used for testing the functionality of the Google Calendar API.`,
        type: AppConfigTypes.STRING
      }
    },
    outlookCalendar: {
      calendarApiRead: {
        value: false,
        title: 'Enable read access to Outlook Calendar API',
        description: `This enables read access to calendars of users belonging to your Microsoft organization or workspace.`,
        type: AppConfigTypes.BOOLEAN
      },
      calendarApiWrite: {
        value: false,
        title: 'Enable read and write access to Outlook Calendar API',
        description: `This enables read and write access to calendars of users belonging to your Microsoft organization or workspace.`,
        type: AppConfigTypes.BOOLEAN
      },
      calendarApiTestEmail: {
        value: '',
        title: 'Calendar API test email',
        description: `An email used for testing the functionality of the Google Calendar API.`,
        type: AppConfigTypes.STRING
      }
    }
  }
};

const replaceObjectValues = (obj) => {
  // this function will iterate through the appConfig object and return only the 'value' field, setting as the new value (replacing the object that contained the 'value' field).
  // ie. generalSettings.sendBySmsEnabled: { value: false, title: 'Sending by SMS', description: "Allows SMS", type: AppConfigTypes.BOOLEAN } },
  // will become generalSettings.sendBySmsEnabled: false,
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if ('value' in obj) {
    return obj.value;
  }

  const newObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        newObj[key] = replaceObjectValues(value);
      } else {
        newObj[key] = value;
      }
    }
  }

  return newObj;
};

const defaultAppConfig = replaceObjectValues(appConfigMeta);

export { appConfigMeta, defaultAppConfig, AppConfigTypes };
