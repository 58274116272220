// react
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';

// redux
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Button,
  Alert
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// tabler icons
import { IconLogout, IconUserCircle, IconPhoto } from '@tabler/icons-react';

// jwt
// import { jwtDecode } from 'jwt-decode';

// notistack
// import { enqueueSnackbar } from 'notistack';

// project constants
// import userTypes from 'constants/userTypes';

// project utils
import getAppVersion from 'utils/app-version';
import { handleLogout } from 'utils/logout';
// import {
//   checkIfEmailBelongsToLoggedInUser,
//   acquireTokenSilentlyAndSyncCalendarData,
//   syncOutlookCalendarEventsToDB
// } from 'utils/msGraphQueryHelper';

// project components
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import User1 from 'assets/images/users/user-round.svg';
import CopyToClipboardWithMUI from 'views/manager/absence-management/CopyToClipBoard';
import userTypes from 'constants/userTypes';
import { useGetEntityName } from 'utils/entities';
import { LoadingButton } from '@mui/lab';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';
import { setManagerProfilePicture } from 'store/features/userSlice';
import { enqueueSnackbar } from 'notistack';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const userData = useSelector((state) => state.user?.data);
  const managerProfilePhoto = userData?.profilePhoto || '';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAccessRole = userData.AccessRoles?.length ? userData.AccessRoles[0].name : 'N/A';
  const userTypeEntityName = useGetEntityName(userData.type === userTypes.ExternalUser ? 'externalUser' : userData?.type);

  // Profile Picture
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [tempPhoto, setTempPhoto] = useState(null);
  const [isExceedPhotoFileSizeLimit, setIsExceedPhotoFileSizeLimit] = useState('');
  const [isSavingPhoto, setIsSavingPhoto] = useState(false);
  const [isClearingPhoto, setIsClearingPhoto] = useState(false);

  const managerInitialPhotoBlobName = managerProfilePhoto.split('/')[managerProfilePhoto.split('/').length - 1] || '';

  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const { appVersion, commitHashShort, commitHashComplete } = getAppVersion();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChangePhoto = (event) => {
    const photoFile = event.target.files[0];
    setTempPhoto(photoFile);
  };

  const handleClosePhotoDialog = () => {
    if (!isSavingPhoto && !isClearingPhoto) {
      setOpenPhotoDialog(false);
      setTempPhoto(null);
    }
  };

  const getNewPhotoURL = async () => {
    if (tempPhoto) {
      const { size } = tempPhoto;
      // File size is greater than 5MB
      const fileSizeByteLimit = 5000000;
      if (size > fileSizeByteLimit) {
        setIsExceedPhotoFileSizeLimit(true);
      }

      const formData = new FormData();
      formData.append('newPhoto', tempPhoto);

      if (managerInitialPhotoBlobName) {
        formData.append('prevPhotoBlobName', managerInitialPhotoBlobName);
      }

      const response = await callAzureFunction({
        url: `/managers/upload-profile-photo`,
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    }
    return null;
  };

  const handleSavePhoto = async () => {
    setIsSavingPhoto(true);
    let snackMsg = 'Profile photo has successfully updated';
    let snackVariant = '';
    try {
      if (tempPhoto) {
        const newPhotoURL = await getNewPhotoURL();

        // Update manager profile photo in database
        await callAzureFunction({
          url: `/manager/profile-photo`,
          method: 'put',
          data: { newPhoto: newPhotoURL }
        });

        dispatch(setManagerProfilePicture(newPhotoURL));
        setTempPhoto(null);
      }
      snackVariant = 'success';
    } catch (error) {
      snackMsg = 'Something happened while updating the profile photo';
      snackVariant = 'error';
      handleError(error);
    } finally {
      setIsSavingPhoto(false);
      handleClosePhotoDialog();
      enqueueSnackbar({
        variant: snackVariant,
        message: <Typography sx={{ maxWidth: 500 }}>{snackMsg}</Typography>,
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      });
    }
  };

  const handleClearPhoto = async () => {
    setIsClearingPhoto(true);
    let snackMsg = 'Profile photo has successfully cleared';
    let snackVariant = '';

    try {
      if (managerInitialPhotoBlobName) {
        const formData = new FormData();
        formData.append('prevPhotoBlobName', managerInitialPhotoBlobName);
        await callAzureFunction({
          url: `/managers/clear-profile-photo/${userData?.id}`,
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        dispatch(setManagerProfilePicture(null));
        setTempPhoto(null);
        snackVariant = 'success';
      }
    } catch (error) {
      snackMsg = 'Something happened while clearing profile photo';
      snackVariant = 'error';
      handleError(error);
    } finally {
      setIsClearingPhoto(false);
      handleClosePhotoDialog();
      enqueueSnackbar({
        variant: snackVariant,
        message: <Typography sx={{ maxWidth: 500 }}>{snackMsg}</Typography>,
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      });
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box ref={anchorRef} style={{ cursor: 'pointer' }} onClick={handleToggle}>
        {userData?.profilePhoto ? (
          <img src={userData?.profilePhoto} style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '50%' }} alt="avatar" />
        ) : (
          <IconUserCircle color="#1ad19e" stroke={1} size={45} />
        )}
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Day,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {userData?.firstName && userData?.lastName ? `${userData.firstName} ${userData.lastName}` : ''}
                        </Typography>
                      </Stack>
                      <Typography my={0.5} variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                        {userTypeEntityName || ''} {userData?.type === userTypes.ExternalUser && `| ${userAccessRole}`}
                      </Typography>
                      {appVersion && commitHashShort && (
                        <Stack direction="row" alignItems="center" mt={1}>
                          <Typography variant="subtitle2">
                            v{appVersion} | {commitHashShort}
                          </Typography>
                          {commitHashComplete && (
                            <CopyToClipboardWithMUI
                              textToCopy={commitHashComplete}
                              iconButtonSx={{ fontSize: '12px' }}
                              showSnackbar={false}
                            />
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                  <Grid style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {/* -- put outlook calendar feature to sleep
                          {userData.type === userTypes.Manager && (
                          <ListItemButton
                            disabled={isLoading}
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            onClick={handleAcquireCalendarToken}
                          >
                            <ListItemIcon>
                              <CalendarMonthIcon stroke={1.2} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Sync Outlook Calendar Events</Typography>} />
                          </ListItemButton>
                        )} */}
                        {userData.type === userTypes.Manager && (
                          <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            onClick={() => {
                              setOpenPhotoDialog(true);
                            }}
                          >
                            <ListItemIcon>
                              <IconPhoto stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Change Profile Picture</Typography>} />
                          </ListItemButton>
                        )}
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          onClick={async () => {
                            await handleLogout();
                            navigate('/login');
                          }}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </Grid>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      {/* Change Profile Picture Dialog */}
      <Dialog open={openPhotoDialog} onClose={handleClosePhotoDialog}>
        <DialogContent>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
            {tempPhoto || managerProfilePhoto ? (
              <Box
                component="img"
                src={tempPhoto ? URL.createObjectURL(tempPhoto) : managerProfilePhoto}
                alt="booking-page-logo"
                sx={{ height: 150, width: 150, borderRadius: '50%', objectFit: 'cover' }}
              />
            ) : (
              <IconUserCircle color="#1ad19e" stroke={1} size={150} />
            )}
            <LoadingButton component="label" variant="text" startIcon={<CloudUploadIcon />} size="small">
              <span>
                Upload Photo
                <VisuallyHiddenInput type="file" accept="image/jpeg,image/png" onChange={(e) => handleChangePhoto(e)} />
              </span>
            </LoadingButton>
          </Stack>
          {isExceedPhotoFileSizeLimit && (
            <Alert severity="error" sx={{ mt: 2 }}>
              File exceeds 5 MB upload limit.
            </Alert>
          )}
          <Stack direction="row" gap={2} mt={4}>
            <div>
              <Button disabled={isSavingPhoto || isClearingPhoto} onClick={() => setOpenPhotoDialog(false)} fullWidth>
                Close
              </Button>
            </div>
            <div>
              <LoadingButton
                disabled={isSavingPhoto || !managerInitialPhotoBlobName}
                loading={isClearingPhoto}
                color="error"
                variant="contained"
                onClick={handleClearPhoto}
                fullWidth
              >
                Clear
              </LoadingButton>
            </div>
            <div>
              <LoadingButton disabled={isClearingPhoto} loading={isSavingPhoto} variant="contained" onClick={handleSavePhoto} fullWidth>
                Update Photo
              </LoadingButton>
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileSection;
